import React from 'react';
import PropTypes from 'prop-types';
import styles from './FlexTitel.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import Text, { SIZE_HEADER_2 } from '../Text/Text';

const FlexTitel = ({ item, ...rest }) => {
    return item ? (
        <Text tag="h2" size={SIZE_HEADER_2} {...rest}>
            {item}
        </Text>
    ) : null;
};

FlexTitel.defaultProps = {
    item: undefined
};

FlexTitel.propTypes = {
    item: PropTypes.string
};

export default React.memo(withClassNames(FlexTitel, styles));

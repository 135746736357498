import React from 'react';
import { render } from 'react-dom';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

import { ApolloProvider } from '@apollo/react-hooks';
import './styles/index.scss';
import { BrowserRouter as Router } from 'react-router-dom';

import MainRoutes from './Routes/MainRoutes';
import { GRAPHQL_PATH } from './utils/paths';
import ScrollToTop from './Compositions/ScrollToTop';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const httpLink = new HttpLink({
    uri: GRAPHQL_PATH
});
const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
    cache,
    link: httpLink
});

const App = () => (
    <Router>
        <ApolloProvider client={client}>
            <ScrollToTop />
            <MainRoutes />
        </ApolloProvider>
    </Router>
);

render(<App />, document.getElementById('root'));

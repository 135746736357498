import React from 'react';
import PropTypes from 'prop-types';

import FlexImage from '../Components/FlexContent/FlexImage';
import FlexTitel from '../Components/FlexContent/FlexTitel';
import FlexText from '../Components/FlexContent/FlexText';

const FlexContentComposition = ({ content, post }) => {
    return content.hgContentFlex
        ? content.hgContentFlex.map((item, index) => (
              <React.Fragment key={index}>
                  {item.hgFlexContentHoofdtekst && (
                      <FlexText html={post.content} />
                  )}
                  <FlexImage item={item.hgFlexContentBeeld} />
                  <FlexTitel item={item.hgFlexContentTitel} />

                  {item.hgFlexContentText && (
                      <FlexText html={item.hgFlexContentText} />
                  )}
              </React.Fragment>
          ))
        : null;
};

FlexContentComposition.defaultProps = {
    content: undefined,
    post: undefined
};

FlexContentComposition.propTypes = {
    content: PropTypes.object,
    post: PropTypes.object
};

export default FlexContentComposition;

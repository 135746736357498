import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import PagingItems from '../Components/PagingItems/PagingItems';
import { SINGLE_MINIMAL_ATTRS } from '../utils/queries';
import PageTemplateList from '../Components/PageTemplate/PageTemplateList';
import ContentPageSize from '../Components/Layout/ContentPageSize';

const ALL_POSTS = gql`
         query posts($cursor: String) {
            posts(first: 40, after: $cursor,where: {orderby: {field: MENU_ORDER, order: DESC}}) {
                edges {
                    node {
                        uri
                        ${SINGLE_MINIMAL_ATTRS}
                        hgPostTitles {
                            hgSubtitle
                        }
                      
                        featuredImage {
                            sourceUrl(size: MEDIUM)
                            srcSet(size: LARGE)
                            sizes(size: LARGE)
                        }
                    }
                }
                pageInfo {
                  endCursor
                  hasPreviousPage
                  startCursor
                  hasNextPage
                }
            }
            
        }
    `;

const AllPosts = () => {
    return (
        <Query query={ALL_POSTS}>
            {({ loading, error, data, fetchMore }) => {
                if (loading) return <div>...</div>;
                if (error) return `Error! ${error}`;
                const posts = data ? data.posts : undefined;
                const items = posts ? posts.edges : [];

                return posts ? (
                    <ContentPageSize>
                        <PagingItems fetchMore={fetchMore} posts={posts}>
                            <PageTemplateList items={items} />
                        </PagingItems>
                    </ContentPageSize>
                ) : null;
            }}
        </Query>
    );
};

export default React.memo(AllPosts);

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './MainNavigation.module.scss';
import { getReactLink } from '../../utils/convertors';
import { withClassNames } from '../HigherOrder/withClassNames';

const MainNavigation = ({ data, ...rest }) => {
    return data ? (
        <ul {...rest}>
            {data.map(({ node }) => (
                <li key={node.id} className={styles.listItem}>
                    <NavLink
                        activeClassName={styles.current}
                        className={styles.navLink}
                        exact={!node.url.includes('projecten')}
                        to={getReactLink(node.url)}
                    >
                        {node.label}
                    </NavLink>
                </li>
            ))}
        </ul>
    ) : null;
};

MainNavigation.defaultProps = {
    data: undefined
};

MainNavigation.propTypes = {
    data: PropTypes.array
};

export default React.memo(withClassNames(MainNavigation, styles));

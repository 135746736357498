import React from 'react';
import PropTypes from 'prop-types';
import styles from './FlexText.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import Parse from '../Parse/Parse';

const FlexText = ({ html, ...rest }) => {
    return html ? (
        <div {...rest}>
            <Parse html={html} />
        </div>
    ) : null;
};

FlexText.defaultProps = {
    html: undefined
};

FlexText.propTypes = {
    html: PropTypes.string
};

export default React.memo(withClassNames(FlexText, styles));

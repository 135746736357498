import React from 'react';
import PropTypes from 'prop-types';

const PagingItems = ({ posts, fetchMore, moreButton, children }) => {
    const onLoadMore = () =>
        fetchMore({
            variables: {
                cursor: posts.pageInfo.endCursor
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult.posts.edges;
                const { pageInfo } = fetchMoreResult.posts;

                return newEdges.length
                    ? {
                          posts: {
                              __typename: previousResult.posts.__typename,
                              edges: [
                                  ...previousResult.posts.edges,
                                  ...newEdges
                              ],
                              pageInfo
                          }
                      }
                    : previousResult;
            }
        });

    return (
        <>
            {children}
            {posts.pageInfo.hasNextPage && (
                <button type="button" onClick={onLoadMore}>
                    {moreButton}
                </button>
            )}
        </>
    );
};

PagingItems.defaultProps = {
    posts: undefined,
    fetchMore: undefined,
    moreButton: <div>more</div>,
    children: undefined
};

PagingItems.propTypes = {
    posts: PropTypes.object,
    fetchMore: PropTypes.func,
    moreButton: PropTypes.node,
    children: PropTypes.node
};

export default PagingItems;

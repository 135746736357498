import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import QueryContainer from '../Containers/QueryContainer';
import PageFull from '../Components/Post/PageFull';

export const PAGE_QUERY = gql`
    query PageQuery($slug: ID!) {
        page(id: $slug, idType: URI) {
            title
            content(format: RENDERED)
            hgGallery {
                hgGalleryField {
                    sourceUrl(size: LARGE)
                    srcSet(size: _2048X2048)
                }
            }
        }
    }
`;

const SinglePage = () => {
    const { slug } = useParams();

    return (
        <>
            <QueryContainer query={PAGE_QUERY} variables={{ slug }}>
                {(data) => {
                    return <PageFull page={data.page} />;
                }}
            </QueryContainer>
        </>
    );
};

export default React.memo(SinglePage);

/* eslint import/no-webpack-loader-syntax: off */

const globalScssVariables = require('sass-extract-loader!../styles/variables/base.scss');

export function SassObjectAsArray(SassObject) {
    return Object.keys(SassObject).map((item) => {
        return SassObject[item].value;
    });
}

export const BREAKPOINTS_NAMES =
    globalScssVariables.global['$breakpoints'].value;

export const BREAKPOINTS = SassObjectAsArray(BREAKPOINTS_NAMES);

export const COLORS_NAMES = globalScssVariables.global['$colors'].value;
export const COLORS = SassObjectAsArray(COLORS_NAMES);

export const MODULE_ROOT = globalScssVariables.global['$MODULE_ROOT'].value;

export const COMPONENTS = globalScssVariables.global['$componentVars'].value;

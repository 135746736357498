import React from 'react';
import PropTypes from 'prop-types';
import styles from './FlexImage.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';

const FlexImage = ({ item, ...rest }) => {
    return item ? (
        <img
            alt={item.altText}
            src={item.sourceUrl}
            srcSet={item.srcSet ? item.srcSet : null}
            {...rest}
        />
    ) : null;
};

FlexImage.defaultProps = {
    item: undefined
};

FlexImage.propTypes = {
    item: PropTypes.object
};

export default React.memo(withClassNames(FlexImage, styles));

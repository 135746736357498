import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './PostSummary.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import Text from '../Text/Text';
import Divider from '../Divider/Divider';
import { POST_PAGE_PATH, ROOT_PATH } from '../../utils/paths';

const PostSummary = ({ data, ...rest }) => {
    const subtitle = data.hgPostTitles && data.hgPostTitles.hgSubtitle;
    const { title, featuredImage: image } = data;

    return (
        <div {...rest}>
            <Link
                className={styles.postLink}
                to={`${ROOT_PATH}${POST_PAGE_PATH}${data.uri}`}
            >
                {subtitle && (
                    <Text tag="h3" className={styles.subtitle}>
                        {subtitle}
                    </Text>
                )}
                <Divider />
                <Text tag="h2" className={styles.title}>
                    {title}
                </Text>
                {image && (
                    <div className={styles.thumb}>
                        <img
                            alt={image.alt}
                            className={styles.img}
                            src={image.sourceUrl}
                            srcSet={image.srcSet}
                            sizes={image.sizes}
                        />
                    </div>
                )}
            </Link>
        </div>
    );
};

PostSummary.defaultProps = {
    data: undefined
};

PostSummary.propTypes = {
    data: PropTypes.object
};

export default React.memo(withClassNames(PostSummary, styles));

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SinglePost from '../Pages/SinglePost';
import SinglePage from '../Pages/SinglePage';
import AllPosts from '../Pages/AllPosts';
import MainNavigation from '../Components/Navigation/MainNavigation';
import NavigationContainer from '../Components/Navigation/NavigationContainer';
import PageTemplate from '../Components/PageTemplate/PageTemplate';
import {
    PAGE_CONTACT,
    PAGE_SINGLE,
    POST_SINGLE,
    POSTS_ALL,
    ROOT_PATH
} from '../utils/paths';
import LandingPage from '../Pages/LandingPage';
import PageContact from '../Components/Post/PageContact';

const MainRoutes = () => {
    return (
        <>
            <NavigationContainer>
                {(data) => <MainNavigation data={data} />}
            </NavigationContainer>
            <PageTemplate>
                <Switch>
                    <Route path={POST_SINGLE} component={SinglePost} />
                    <Route path={POSTS_ALL} component={AllPosts} />
                    <Route path={PAGE_CONTACT} component={PageContact} />
                    <Route path={PAGE_SINGLE} component={SinglePage} />

                    <Route path={ROOT_PATH} component={LandingPage} />
                </Switch>
            </PageTemplate>
        </>
    );
};

export default MainRoutes;

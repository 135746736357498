import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';

const NavigationQuery = gql`
    query MyQuery {
        menus(where: { slug: "main" }) {
            nodes {
                menuItems {
                    edges {
                        node {
                            id
                            url
                            label
                        }
                    }
                }
            }
        }
    }
`;

const NavigationContainer = ({ children }) => (
    <Query query={NavigationQuery}>
        {({ loading, error, data }) => {
            if (loading) return <p>...</p>;
            if (error) return <p>Error :(</p>;

            const menuItems =
                data &&
                data.menus &&
                data.menus.nodes &&
                data.menus.nodes[0].menuItems
                    ? data.menus.nodes[0].menuItems.edges
                    : undefined;

            return menuItems ? children(menuItems) : <div>not found</div>;
        }}
    </Query>
);

NavigationContainer.propTypes = {
    children: PropTypes.func.isRequired
};

export default NavigationContainer;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Text.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';

export const SIZE_HEADER_1 = 'header-1';
export const SIZE_HEADER_2 = 'header-2';
export const SIZE_HEADER_3 = 'header-3';
export const SIZE_BODY = 'body';
export const SIZE_BODY_SMALL = 'body-small';

const Text = ({ tag: Tag, center, className, children, size, ...rest }) => {
    const centerClassName = center && styles.center;

    return (
        <Tag
            className={classNames(className, styles[size], centerClassName)}
            {...rest}
        >
            {children}
        </Tag>
    );
};

Text.defaultProps = {
    className: '',
    children: undefined,
    size: undefined,
    tag: 'div',
    center: false
};

Text.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    center: PropTypes.bool,
    tag: PropTypes.string
};

export default React.memo(withClassNames(Text, styles));

import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageTemplateList.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import PostSummary from '../Post/PostSummary';
import useWindowDimensions from '../../utils/windowSizeHook';

const PageTemplateList = ({ items, ...rest }) => {
    const { isSmallerThanMedium } = useWindowDimensions();
    const col1 = [];
    const col2 = [];
    const col3 = [];
    if (!isSmallerThanMedium) {
        let counter = 0;

        // eslint-disable-next-line array-callback-return
        items.map((item) => {
            counter = counter === 3 ? 1 : counter + 1;
            if (counter === 1) {
                col1.push(item);
            }
            if (counter === 2) {
                col2.push(item);
            }
            if (counter === 3) {
                col3.push(item);
            }
        });
    }
    return (
        <div {...rest}>
            {isSmallerThanMedium ? (
                items.map((item) => (
                    <PostSummary
                        key={item.node.id}
                        data={item.node}
                        className={styles.item}
                    />
                ))
            ) : (
                <>
                    <div className={styles.col1}>
                        {col1.map((item) => (
                            <PostSummary
                                key={item.node.id}
                                data={item.node}
                                className={styles.item}
                            />
                        ))}
                    </div>
                    <div className={styles.col2}>
                        {col2.map((item) => (
                            <PostSummary
                                key={item.node.id}
                                data={item.node}
                                className={styles.item}
                            />
                        ))}
                    </div>
                    <div className={styles.col3}>
                        {col3.map((item) => (
                            <PostSummary
                                key={item.node.id}
                                data={item.node}
                                className={styles.item}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

PageTemplateList.defaultProps = {
    items: undefined
};

PageTemplateList.propTypes = {
    items: PropTypes.array
};

export default React.memo(withClassNames(PageTemplateList, styles));

import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';

const QueryContainer = ({ query, variables, children }) => (
    <Query query={query} variables={variables}>
        {({ loading, error, data }) => {
            if (loading) return <p>...</p>;
            if (error) return <p>Error :(</p>;

            return data ? children(data) : <div>not found</div>;
        }}
    </Query>
);

QueryContainer.propTypes = {
    query: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    variables: PropTypes.object.isRequired
};

export default QueryContainer;

import React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const Parse = ({ html }) => {
    return html ? <>{parse(html)}</> : null;
};

Parse.defaultProps = {
    html: undefined
};

Parse.propTypes = {
    html: PropTypes.string
};

export default Parse;

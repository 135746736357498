import React from 'react';
import QueryContainer from '../Containers/QueryContainer';
import PageHome from '../Components/Post/PageHome';
import { PAGE_QUERY } from './SinglePage';

const LandingPage = () => {
    return (
        <QueryContainer query={PAGE_QUERY} variables={{ slug: 'bregtbalk' }}>
            {(data) => {
                return <PageHome page={data.page} />;
            }}
        </QueryContainer>
    );
};

export default React.memo(LandingPage);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withclassnamestyles from './withClassNames.module.scss';
import { CssModulesClassName } from '../../utils/cssModules';
import { mergeStringsToArray } from '../../utils/utils';
import { MODULE_ROOT } from '../../utils/sharedScssVariables';

/*
 * WrappedComponent  : [PropTypes.element] The component to be fed with our classlist
 *
 * styles            : [PropTypes.object] The css-module style object (usually: the [component].module.scss file imported in the WrappedComponent)
 *
 * componentClassNames: [PropTypes.string] Usually the name of the component, the main className for the component. Space seperated other styles[classNames] allowed
 *
 * */

export function withClassNames(
    WrappedComponent,
    styles,
    componentClassNames = MODULE_ROOT
) {
    return class extends PureComponent {
        static propTypes = {
            dispatch: PropTypes.func,
            staticContext: PropTypes.object,
            styleName: PropTypes.string,
            className: PropTypes.string
        };

        static defaultProps = {
            dispatch: undefined,
            staticContext: undefined,
            styleName: '',
            className: ''
        };

        render() {
            const {
                styleName,
                className,
                dispatch,
                staticContext,
                ...rest
            } = this.props;
            const cursorClass =
                typeof rest.onClick === 'function' &&
                withclassnamestyles.clickable;

            const mergedStyleNames = mergeStringsToArray(
                componentClassNames,
                styleName
            );

            return (
                <WrappedComponent
                    className={classNames(
                        CssModulesClassName(mergedStyleNames, styles),
                        cursorClass,
                        className
                    )}
                    {...rest}
                />
            );
        }
    };
}

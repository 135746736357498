import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageContact.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import ContentPageSize from '../Layout/ContentPageSize';
import ColumnContainer from '../Columns/ColumnContainer';
import Column from '../Columns/Column';
import BregtPhoto from '../../assets/bregtbalk.png';
import Locatie from '../../assets/kaart.png';
import FlexImage from '../FlexContent/FlexImage';

const PageContact = ({ page, ...rest }) => {
    return (
        <ContentPageSize {...rest}>
            <ColumnContainer>
                <Column
                    styleName="acf-hg-third"
                    className={styles.addressColumn}
                >
                    <div className={styles.flexCol}>
                        <p className={styles.subHead}>Studio</p>
                        <address className={styles.address}>
                            KNSM-laan 155
                            <br />
                            1019 LC Amsterdam
                            <br />
                            <a href="tel:+310206825212">+31 (0)20 682 52 12</a>
                            <br />
                        </address>
                        <a href="mailto:info@bregtbalk.nl">info@bregtbalk.nl</a>

                        <FlexImage
                            className={styles.bregtFoto}
                            item={{
                                altText: 'Bregt Balk',
                                sourceUrl: BregtPhoto
                            }}
                        />
                    </div>
                </Column>
                <Column styleName="acf-hg-twothird">
                    <FlexImage
                        item={{ altText: 'KNSM-laan 155', sourceUrl: Locatie }}
                    />
                </Column>
            </ColumnContainer>
        </ContentPageSize>
    );
};

PageContact.defaultProps = {
    page: undefined
};

PageContact.propTypes = {
    page: PropTypes.object
};

export default React.memo(withClassNames(PageContact, styles));

import React from 'react';
import PropTypes from 'prop-types';
import ColumnContainer from '../Components/Columns/ColumnContainer';
import ColumnComposition from './ColumnComposition';

const ColumnContainerComposition = ({ columns, post }) => {
    return columns && columns.hgColumngroup
        ? columns.hgColumngroup.map((column, index) => (
              <ColumnContainer key={index}>
                  <ColumnComposition column={column} post={post} />
              </ColumnContainer>
          ))
        : null;
};

ColumnContainerComposition.defaultProps = {
    columns: undefined,
    post: undefined
};

ColumnContainerComposition.propTypes = {
    columns: PropTypes.object,
    post: PropTypes.object
};

export default ColumnContainerComposition;

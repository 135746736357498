import React from 'react';
import PropTypes from 'prop-types';
import Column, { ACF_PREFIX } from '../Components/Columns/Column';
import FlexContentComposition from './FlexContentComposition';

const ColumnComposition = ({ column, post }) => {
    return column.hgColumn
        ? column.hgColumn.map((content, index) => (
              <Column
                  key={index}
                  styleName={`${ACF_PREFIX}${content.hgColumnFormaat}`}
              >
                  <FlexContentComposition content={content} post={post} />
              </Column>
          ))
        : null;
};

ColumnComposition.defaultProps = {
    columns: undefined,
    post: undefined
};

ColumnComposition.propTypes = {
    columns: PropTypes.object,
    post: PropTypes.object
};

export default ColumnComposition;

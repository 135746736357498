import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageFull.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import Text from '../Text/Text';
import ContentPageSize from '../Layout/ContentPageSize';
import Parse from '../Parse/Parse';

const PageFull = ({ page, ...rest }) => {
    return (
        <ContentPageSize {...rest}>
            <div style={{ padding: 16 }}>
                <Text tag="h1" className={styles.fullTitle}>
                    {page.title}
                </Text>
                <Parse html={page.content} />
            </div>
        </ContentPageSize>
    );
};

PageFull.defaultProps = {
    page: undefined
};

PageFull.propTypes = {
    page: PropTypes.object
};

export default React.memo(withClassNames(PageFull, styles));

import React, { useState } from 'react';
import useInterval from '@use-it/interval';
import PropTypes from 'prop-types';
import styles from './PageHome.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import Parse from '../Parse/Parse';

const PageHome = ({ page, ...rest }) => {
    const [slide, setSlide] = useState(0);

    const slideCount = page.hgGallery
        ? page.hgGallery.hgGalleryField.length
        : 0;

    useInterval(() => {
        setSlide((currentCount) => {
            const nextSlide = currentCount + 1;
            return nextSlide > slideCount - 1 ? 0 : nextSlide;
        });
    }, 5000);

    const image =
        page.hgGallery && page.hgGallery.hgGalleryField
            ? page.hgGallery && page.hgGallery.hgGalleryField[slide]
            : undefined;
    return (
        <div {...rest}>
            <div className={styles.fullBackground}>
                {image && (
                    <img
                        alt={image.alt}
                        className={styles.mainImg}
                        src={image.sourceUrl}
                        srcSet={image.srcSet}
                        sizes={image.sizes}
                    />
                )}
            </div>
            <div className={styles.pageConstrain}>
                <div className={styles.pageContent}>
                    <Parse html={page.content} />
                </div>
            </div>
        </div>
    );
};

PageHome.defaultProps = {
    page: undefined
};

PageHome.propTypes = {
    page: PropTypes.object
};

export default React.memo(withClassNames(PageHome, styles));

import gql from 'graphql-tag';

export const SINGLE_MINIMAL_ATTRS = `
    __typename
    id
    title
    excerpt(format: RENDERED)
`;

export const SINGLE_FULL_ATTRS = `
    content(format: RENDERED)
`;

export const SINGLE_ALL_ATTRS = `
    ${SINGLE_MINIMAL_ATTRS}
    ${SINGLE_FULL_ATTRS}
`;

export const PROJECT_ACF_ATTRS = `
projectData {
    hgDate {
        hgProjectDate
        hgProjectDisplayDate
    }
    hgProjectColofon {
        hgProjectColofonLabel
        hgProjectColofonValue
    }
    hgWebsites {
        hgUrl
        hgUrlNote
        hgUrlStatus
        urlDisplay
    }
    hgProjectClient {
    ... on Client {
            id
            title(format: RENDERED)
            uri
        }
    }
}
`;

export const PROJECT_ACF_PHOTOS = `
    photos {
        hgPhotos {
            srcSet(size: THUMBNAIL)
            title
            status
            sizes(size: THUMBNAIL)
            sourceUrl(size: THUMBNAIL)
        }
    }
`;
export const ID_TYPE_SLUG = 'SLUG';
export const ID_VARS = {
    [ID_TYPE_SLUG]: '$slug'
};
export const POST_TYPE_POST = 'POST';
export const POST_TYPE_PAGE = 'PAGE';
export const POST_TYPE_PROJECT = 'PROJECT';

export const POST_TYPES = {
    [POST_TYPE_POST]: 'Post',
    [POST_TYPE_PAGE]: 'Page',
    [POST_TYPE_PROJECT]: 'Project'
};

export const fetchContentNodeBy = (
    nodeType = POST_TYPE_POST,
    idType = ID_TYPE_SLUG
) => {
    const idVar = ID_VARS[idType];
    return gql`
        query CONTENT_NODE_BY(${idVar}: ID!) {
          contentNode(id: ${idVar}, idType: URI, contentType: ${nodeType}) {          
            ... on ${POST_TYPES[nodeType]} {
              id
              ${SINGLE_ALL_ATTRS}
            }
          }
        }
        
    `;
};

export const fetchProjectsPaged = after => {
    return gql`
         query ALL_PROJECTS($after: String) {
            projects(first: 2, after: $after) {
                nodes {
                    ${SINGLE_ALL_ATTRS}
                }
                pageInfo {
                  endCursor
                  hasPreviousPage
                  startCursor
                  hasNextPage
                }
            }
            
        }
       
    `;
};

export const GQL_ALL_PROJECTS = gql`
    query ALL_PROJECTS {
        projects {
            nodes {
                ${SINGLE_ALL_ATTRS}
            }
        }
    }
`;
